@import 'utils';

.heading {
  @include up(desktop) {
    @include flex;
    justify-content: space-between;
    gap: spacing(20);
    > * {
      flex: 1;
    }
  }
}

.heading,
.title {
  margin-bottom: spacing(6);
}

.cards {
  display: grid;
  gap: spacing(10);
  @include up(desktop) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-template-rows: auto 1fr auto;
  }
  > * {
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 3;
    row-gap: spacing(3);
  }
}
