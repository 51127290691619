@import 'utils';

.user_actions_dropdown {
  align-items: center;
  display: flex;
  height: 100%;
}

.action_btn {
  &,
  b {
    color: var(--color-product-text);

    &:hover {
      color: var(--color-utility-interaction-600);

      svg {
        color: var(--color-utility-interaction-600);
      }
    }
  }
}
