@import 'utils';

.stats_bar {
  width: 100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  .stats_bar_lightbulb {
    color: var(--color-picton-blue);
  }

  .circle {
    color: var(--color-neutral-500);
    margin: 0 2%;
  }

  & > div, & > div > span {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
}
