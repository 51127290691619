@import 'utils';

.poll_choices_container {
  @include flex-column;
  align-items: flex-start;
  gap: spacing(3);

  .poll_choices {
    @include flex-column;
    gap: spacing(2);
    width: 100%;

    .poll_choice {
      align-items: center;
      column-gap: spacing(2);
      display: grid;
      grid-template-columns: auto 30px;

      textarea {
        border: 1px solid var(--color-neutral-500);
        flex: 1;
        height: fit-content;
        padding: spacing(2);
        resize: none;
        width: 100%;
      }

      svg {
        align-self: center;
        justify-self: center;
      }
    }
  }

  .add_choice {
    align-items: center;
    color: var(--color-utility-interaction-500);
    display: flex;
    justify-content: center;

    span {
      margin-top: 2px;
    }
  }
}
