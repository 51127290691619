@import 'utils';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: spacing(3);
  width: 100%;
  @include up(tablet) {
    width: auto;
  }
  .label {
    font-weight: 500;
    color: var(--color-black);
    gap: spacing(4);
    width: fit-content;
    text-wrap: nowrap;
  }

  .dropdown {
    height: fit-content;
    width: 100%;
    min-width: spacing(60);

    &:focus-within {
      .selected {
        box-shadow: inset 0px -1px var(--color-utility-interaction-600);
        border-bottom: 1px solid var(--color-utility-interaction-600);
        .show_more {
          rotate: 0.5turn;
        }
      }
    }

    @include up(tablet) {
      width: auto;
    }

    button,
    .custom_menu {
      width: 100%;
    }

    .custom_menu {
      padding: spacing(2) 0;
      border-radius: 0 0 4px 4px;
    }

    .selected {
      font-weight: 400;
      color: var(--color-black);
      gap: spacing(3);
      padding: spacing(3) spacing(4);
      border-bottom: 1px solid var(--color-neutral-500);
      background-color: var(--color-initeration-tint-01);
      justify-content: space-between;
      display: flex;
      width: 100%;
      align-items: center;

      .icon {
        color: var(--color-product-text);
      }

      &:hover {
        border-bottom: 1px solid var(--color-utility-interaction-600);
      }
    }

    .item_label {
      line-height: 24px;
      color: var(--color-product-text);
      display: flex;
      justify-content: space-between;
      padding: spacing(2) spacing(5);
      cursor: pointer;
      width: 100%;
      &:hover {
        background-color: var(--color-utility-interaction-25);
      }
    }

    .item {
      line-height: 18px;
      padding: 0;
      text-align: start;

      &:not(:last-child) {
        border-bottom: none;
      }

      .checked_item {
        background-color: var(--color-neutral-100);
      }

      .link:focus-visible {
        outline-offset: -3px;
      }
    }
  }
}
