@import 'utils';

.stats {
  margin: auto 0 0 0;
  display: flex;
  align-items: center;
  gap: spacing(6);
}

.stat {
  display: flex;
  gap: spacing(2);
}

.upvotes {
  svg {
    margin-top: -1px;
  }
}
