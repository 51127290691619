@import 'utils';

.stats_minimal {
  margin: auto 0 0 0;
  display: flex;
  align-items: center;
}

.with_separator {
  &:before {
    content: '';
    vertical-align: middle;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    display: inline-block;
    background: var(--color-neutral-500);
    margin: 0 spacing(3) 1px spacing(3);
  }
}
