@import 'utils';

@keyframes placeHolderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

.animated_background {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: var(--color-cultured-white);
  background: linear-gradient(
                  to right,
                  var(--color-neutral-400) 10%,
                  var(--color-neutral-400) 20%,
                  var(--color-neutral-400) 40%
  );
  background-size: 800px 200px;
  height: 300px;
  padding: 1ex;
  position: relative;

  .frame {
    background: transparent;
    border: 20px solid var(--color-cultured-white);
    font-size: 0;
    height: 100%;
    padding-bottom: 5px;
    padding-left: 5px;
    // remove space between inline-block elements, with font-size:0;
    width: 100%;

    .inner_holder {
      align-content: space-between;
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      justify-content: space-between;
      width: 100%;

      .inner {
        background-color: var(--color-cultured-white);
        height: 32.8%;
        width: 32.8%;
        @include up(tablet) {
          height: 33.1%;
          width: 33.1%;
        }
      }
    }
  }
}
