@import 'utils';

.modal_user_actions {
  align-self: flex-start;

  .modal_header_actions {
    margin-bottom: spacing(3);
  }

  .modal_body_actions {
    padding: spacing(3) spacing(8) spacing(8);
    .preview_alert {
      margin-bottom: spacing(6);
      color: var(--color-neutral-800);
    }
    .preview_card > div {
      min-width: unset !important;
      margin: 0;
    }
    .preview_content {
      display: flex;
      gap: spacing(2);
      .preview_tips {
        flex: 1;
        list-style: none;
        li + li {
          margin-top: spacing(2);
        }
      }
    }
  }

  .submit {
    align-items: center;
    display: flex;
    width: 100%;
    gap: spacing(3);
    padding-top: 10px;
    justify-content: flex-end;
    button {
      border-radius: 4px;
    }
    b {
      font-weight: 500;
    }
  }

  .form {
    @include flex-column;
    gap: spacing(6);

    textarea {
      padding: 10px;
      resize: none;
    }
  }

  .tabs {
    letter-spacing: 0.8px;
  }

  div[data-type='poll-item'] {
    background-color: var(--color-neutral-200);
    cursor: pointer;
    &:hover {
      background-color: var(--color-interaction-tint-02);
    }
  }
}

.preview_header {
  display: flex;
  align-items: center;
  gap: spacing(2);
}
.back_btn {
  border-radius: 4px;
  color: var(--color-foreground-hex);
  padding: spacing(2);
  &:hover {
    color: var(--color-foreground-hex);
    background-color: var(--color-utility-interaction-25);
  }
}
