@import 'utils';

.chip {
  align-items: center;
  background-color: var(--background-gex);
  border: 1px solid var(--color-neutral-500);
  border-radius: 24px;
  display: flex;
  gap: spacing(3);
  padding: 6px spacing(3);
  white-space: nowrap;
  width: fit-content;

  .label {
    color: var(--color-product-text);
  }

  .close {
    color: var(--color-product-text);
  }

  &:hover {
    border: 1px solid var(--color-utility-interaction-500);
  }

  &.active {
    margin: 4px;
    outline: 2px solid var(--color-utility-interaction-200);
    outline-offset: 2px;
  }
}
