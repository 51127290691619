@import 'utils';

.search {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;

  .search_icon {
    color: var(--color-brand-gartner-blue);
    cursor: pointer;
    display: inline-block;

    @include up(tablet) {
      display: none;
    }
  }

  &.search_true {
    background-color: var(--color-background-hex);
    gap: spacing(5);
    height: 80px;
    left: 0;
    padding: 0 spacing(5);
  }
}
