@import 'utils';

.stats_actions_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include up(desktop) {
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.stats,
.actions {
  display: flex;
  flex-wrap: nowrap;
  padding: spacing(5);
  width: 100%;
  @include up(desktop) {
    padding: 0;
  }
}

.stats {
  justify-content: flex-end;
}

.actions {
  align-items: center;
  display: flex;
  justify-content: space-around;
  padding: spacing(2) 0;
  @include up(desktop) {
    gap: spacing(4);
    justify-content: flex-start;
  }
}

.container_one_line {
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  padding: spacing(3) 0;

  .stats,
  .actions {
    padding: 0;
  }

  .stats {
    justify-content: flex-end;
  }

  .actions {
    gap: spacing(2);
    justify-content: flex-start;
  }
}
