@import 'utils';

.button.tab {
  color: var(--color-neutral-600);
  letter-spacing: inherit;
  padding: spacing(3) spacing(6);
  position: relative;
  text-transform: uppercase;
  top: 2px;

  &.tab_selected {
    border-bottom: 2px solid var(--color-foreground-hex);
    color: var(--color-product-text);
    cursor: default;
    font-weight: 900;

    &:hover {
      background-color: transparent;
    }
  }

  * {
    margin-top: 2px;
  }

  &:hover {
    background-color: var(--color-neutral-300);
    color: var(--color-product-text);
  }
}
