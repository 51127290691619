@import 'utils';

.modal_awaiting_for_approval {
  .image {
    display: block;
    margin: 0 auto 30px;
  }

  .containerList {
    margin-bottom: 15px;
    padding: 25px;
  }

  .modal_text {
    @include flex-column;
    gap: spacing(6);
    @include up(tablet) {
      @include flex-row;
    }
  }

  .content {
    @include flex-column;
    gap: spacing(6);
  }

  .text {
    @include flex-column;
    gap: 16px;
  }

  .title {
    margin-bottom: spacing(4);
  }
}
