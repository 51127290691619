@import 'utils';

.item {
  background-color: rgba(236, 251, 249, 1);
  margin-bottom: spacing(3);
  position: relative;
}

.option {
  font-size: 16px;
  line-height: 24px;
  padding: spacing(3) 50px spacing(3) 21px;
  position: inherit;
  z-index: 2;
}

.result {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.bar {
  background: rgba(141, 230, 220, 1);
  inset: 0;
  position: absolute;
}

.winner-true {
  font-weight: 600;

  .result {
    font-weight: 600;
  }
}
