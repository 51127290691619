@import 'utils';

.container {
  margin-left: spacing(2);

  a {
    margin-left: spacing(2);
    padding: spacing(4) 0;
  }
}

.menu_item {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-neutral-500);
  }
}

.title {
  color: var(--color-neutral-600);
}
