@import 'utils';

.highlight {
  display: inline-flex;
  align-items: center;
  color: var(--color-utility-interaction-500);

  &:hover {
    text-decoration: underline;
  }
}
