@import 'utils';

.next_link {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

.stats_bar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: spacing(2) 0;
  width: 40%;

  .stats_bar_lightbulb {
    color: var(--color-picton-blue);
  }

  .circle {
    color: var(--color-neutral-500);
    margin: 0 2%;
  }

  & > span,
  & > a,
  .next_link {
    display: flex;
    font-size: 14px;
    line-height: 20px;
  }

  .next_link:hover,
  a:hover {
    color: var(--color-utility-interaction-500);
    text-decoration: underline;
  }
}
