@import 'utils';

.comment_wrapper {
  display: flex;
  flex-direction: column;

  .comment {
    display: flex;
    gap: spacing(2);
    justify-content: space-between;
    margin-bottom: spacing(6);
    @include up(desktop) {
      padding-left: spacing(6);
    }

    .comment_box {
      background: var(--color-cultured-white);
      color: var(--color-product-text);
      padding: spacing(4) spacing(4) spacing(2);
      width: 100%;

      .comment_box_content {
        align-items: flex-start;
        align-self: stretch;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: spacing(2);

        .comment_box_top {
          display: flex;
          flex-wrap: wrap;
          font-size: 12px;
          justify-content: space-between;
          line-height: 14px;
          width: 100%;

          .comment_box_title {
            @include flex-column;
            font-size: 14px;
            gap: spacing(1);
            line-height: spacing(4);

            span {
              color: var(--color-brand-gartner-blue);
            }
          }
        }

        .comment_box_text {
          font-size: 16px;
          line-height: 24px;
          overflow-wrap: anywhere;

          .read_more {
            color: var(--color-brand-gartner-blue);
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .comment_box_actions {
        font-size: 16px;
        padding: spacing(6) 0 0;
      }
    }

    .comment_bottom {
      display: flex;
      justify-content: space-between;
    }
  }
}
