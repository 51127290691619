@import 'utils';

.poll_data {
  overflow: hidden;
  padding: 0 spacing(6);
  position: relative;

  &.showShadow:after {
    background: linear-gradient(0deg, var(--color-background-hex) 50%, transparent 100%);
    bottom: spacing(3);
    content: '';
    height: 60px;
    position: absolute;
    width: 100%;
    z-index: 10;
  }

  .poll_bottom {
    display: flex;
    flex-direction: column;
    gap: spacing(3);
    margin-bottom: spacing(5);

    .participants {
      color: var(--color-product-text);
      font-weight: 500;

      svg {
        color: var(--color-neutral-500);
        vertical-align: middle;
      }
    }
  }
}

@include up(tablet) {
  .poll_data {
    .poll_bottom {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
