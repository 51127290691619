@import 'utils';

.topics_list {
  @include flex-column;
  gap: spacing(2);
}

.topic {
  background-color: var(--color-neutral-200);
  padding: spacing(3) spacing(5);
}

.show_more_less_button {
  display: flex;
  justify-content: flex-start;
  margin-top: spacing(2);
  padding-left: spacing(2);

  span {
    font-weight: 500;
  }
}

.hidden {
  display: none;
}
