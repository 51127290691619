@import 'utils';

.left_sidebar {
  display: none;
  width: 310px;
  @include up(tablet) {
    display: flex;
    flex-direction: column;
  }

  .label {
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    padding-bottom: spacing(2);
  }
}
