@import 'utils';

.sortCommentsWrapper {
  align-items: center;
  display: flex;
  gap: 0 1rem;
  justify-content: flex-end;
  margin-block: 2rem;
  padding-left: spacing(6);

  @include down(tablet) {
    margin-right: 1rem;
  }
}
