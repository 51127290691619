@import 'utils';

.search_input {
  border: 0;
  flex-grow: 1;
  font-size: 16px;
  line-height: 24px;
  margin: 0 spacing(2);
  outline: none;

  &:placeholder-shown + button {
    display: none;
  }

  &:focus ~ .dropdown_container {
    display: flex;
  }

  &:not(:placeholder-shown) ~ .dropdown_container {
    display: none;
  }
}

.dropdown_container {
  position: absolute;
  right: 0;
  left: 0;
  top: 54px;
  border-radius: 4px;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-card);
  cursor: pointer;
  padding: spacing(4);
  display: none;

  &:hover {
    background-color: var(--color-interaction-tint-02);
  }

  &:active {
    opacity: 0.3;
    display: flex;
  }
}
