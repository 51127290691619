@import 'utils';

.next_link {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  word-break: break-word;
}

.card_wrapper {
  contain-intrinsic-size: auto 1000px; //average of each card
  content-visibility: auto;
  box-shadow: var(--box-shadow-01-normal);
  border-radius: var(--border-radius);
  transition: box-shadow 0.3s ease-in-out;

  &:not(:last-of-type) {
    margin-bottom: spacing(12);
  }

  &:hover {
    box-shadow: var(--box-shadow-01-raised);
  }

  .view_results {
    margin: spacing(2) 0 spacing(6) spacing(6);
  }

  .card {
    background-color: var(--neutrals-01-white);
    border-radius: var(--border-radius);
    transition: box-shadow 0.3s ease-in-out;

    .card_top {
      padding: spacing(6);

      h1,
      h3 {
        color: var(--color-product-text);
        font-size: 22px;
        line-height: spacing(8);
        margin-bottom: spacing(3);
      }
    }

    .card_poll {
      @include up(desktop) {
        padding: 0 spacing(6) spacing(4) spacing(6);
      }
    }

    .card_comments {
      padding: 0 spacing(6);
    }

    .card_read_comments {
      color: inherit;
      color: var(--color-brand-gartner-blue);
      cursor: pointer;
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      line-height: 100%;
      margin: 0 spacing(6) spacing(6);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@include up(desktop) {
  .card_wrapper {
    .card {
      min-width: 710px;
      position: relative;
      z-index: 2;

      .card_top {
        h1,
        h3 {
          font-size: 22px;
          line-height: spacing(8);
          margin-bottom: spacing(6);
        }
      }
    }
  }
}

.inner_driver {
  border-top: 1px solid var(--color-neutral-300);
}

@include up(desktop) {
  .inner_driver {
    display: none;
  }
  .stats_actions {
    padding: spacing(5) spacing(6);
  }
}

.stats_actions_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include up(desktop) {
    align-items: center;
    border-top: 1px solid var(--color-neutral-300);
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.stats,
.actions {
  display: flex;
  flex-wrap: nowrap;
  padding: spacing(5);
  width: 100%;
  @include up(desktop) {
    padding: 0;
  }
}

.stats {
  justify-content: flex-end;
}

.actions {
  justify-content: space-around;
  @include up(desktop) {
    gap: spacing(4);
    justify-content: flex-start;
  }
}
