@import 'utils';

.container > section {
  margin: 0 auto;
  max-width: 1440px;
  padding: spacing(9) spacing(5);
  width: 100%;
  @include up(tablet) {
    padding: spacing(15);
  }
}

.container {
  .banner_msg {
    color: var(--color-product-text);
    line-height: 130%;
    @include up(tablet) {
      font-size: 45px;
    }
  }
}

.highlights,
.trending_omis,
.faqs {
  @include full_bleed();
}

.bg_wrapper {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 250px;
  overflow: hidden;
  position: relative;
  z-index: -1;

  .bg_img {
    object-fit: cover;
    object-position: center center;
    @include up(tablet) {
      object-fit: none;
      object-position: center right;
    }
  }

  @include up(tablet) {
    height: 500px;
    left: 0;
    margin: 0 auto;
    max-width: 1440px;
    position: absolute;
    right: 0;
  }
}

.banner {
  .banner_card {
    align-items: flex-start;
    background: var(--neutrals-01-white);
    display: flex;
    flex-direction: column;
    gap: spacing(6);
    justify-content: center;
    max-width: 640px;
    padding: spacing(4);
  }

  @include up(tablet) {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 500px;
    justify-content: center;
    width: max(50%, 650px);
  }
}
