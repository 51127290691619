@import 'utils';

.main {
  margin: spacing(10) auto;
  max-width: 1440px;
  padding: 0 spacing(5);
  @include up(tablet) {
    padding: 0 60px;
  }

  .title {
    margin-bottom: 22px;
    @include up(desktop) {
      font-size: 45px;
      line-height: 1.25;
    }
  }
}

.omi_list {
  list-style: none;
  margin-top: spacing(10);
  @include up(desktop) {
    display: grid;
    gap: 60px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto-fill);
  }

  > li:not(:last-of-type) {
    margin-bottom: spacing(5);
    @include up(desktop) {
      margin-bottom: 0;
    }
  }
}

.card {
  @include flex-column;
  align-items: flex-start;
  box-shadow: var(--box-shadow-01-normal);
  gap: spacing(3);
  padding: spacing(6);

  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: var(--box-shadow-01-raised);
  }

  .card_image {
    margin-bottom: spacing(3);
    width: 100%;

    img {
      height: auto;
      width: 100%;
    }
  }

  .card_link {
    margin-top: auto;
  }

  .card_content {
    margin-bottom: spacing(6);

    > p {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      margin-bottom: spacing(3);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
  }
}
