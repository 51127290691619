@import 'utils';

.related_topics {
  .title {
    color: var(--color-product-text);
    margin-bottom: spacing(4);
    text-transform: uppercase;
  }

  .topic_list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: spacing(4);
  }
}
