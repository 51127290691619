@import './breakpoints';
@import './base';

@function spacing($amount: 1) {
  $base: 4px;
  @return $base * $amount;
}

/// Mixin to create a full bleed background effect, extending beyond the element's natural boundaries.
///
/// This mixin applies a background color that stretches across the viewport width, even when the element
/// itself is constrained. It uses a large box shadow and a clip-path to ensure that the background color
/// fills the viewport without extra HTML structure or CSS positioning.
@mixin full_bleed($bgColor: var(--color-cultured-white)) {
  background-color: $bgColor;
  box-shadow: 0 0 0 100vmax $bgColor;
  clip-path: inset(0 -100vmax);
}
