@import 'utils';

.dropdown {
  position: relative;

  .dropdown_menu {
    background-color: var(--color-background-hex);
    box-shadow: var(--box-shadow-medium);
    display: flex;
    flex-direction: column;
    max-height: 85dvh;
    overflow-y: auto;
    padding: spacing(4) spacing(6);
    position: absolute;
    top: 100%;
    width: 272px;
    z-index: 100;

    &.menu_right {
      right: 0;
    }
  }

  .button {
    color: var(--color-product-text);
  }
}

.dropdown_item {
  line-height: 18px;
  padding: 0px;
  padding: spacing(4) spacing(2);
  text-align: start;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-neutral-500);
  }
}
