@import 'utils';

.poll_data {
  padding: 0 spacing(6);

  .poll_bottom {
    display: flex;
    flex-direction: column;
    gap: spacing(3);
    margin-bottom: spacing(5);
 
    .participants {
      color: var(--color-product-text);
      font-weight: 500;

      svg {
        color: var(--color-neutral-500);
        vertical-align: middle;
      }
    }
  }
}

@include up(tablet) {
  .poll_data {
    .poll_bottom {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
