@import 'utils';

.select_root {
  align-items: center;
  color: var(--color-brand-gartner-blue);
  display: inline-flex;

  select {
    background-color: transparent;
    appearance: none;
    border: none;
    border-bottom: 2px solid var(--color-utility-interaction-700);
    color: var(--color-foreground-hex);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: spacing(2) spacing(8) spacing(2) spacing(2);
  }

  label {
    font-weight: 500;
    line-height: 20px;
    margin-right: spacing(4);
  }

  .select_arrow {
    left: -20px;
    pointer-events: none;
    position: relative;
  }
}
