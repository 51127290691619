@import 'utils';

.card_wrapper {
  &:not(:last-of-type) {
    margin-bottom: 48px;
  }

  .card {
    background-color: var(--neutrals-01-white);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-01-normal);
    margin: 0 0 spacing(4) spacing(6);
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
      box-shadow: var(--box-shadow-01-raised);
    }

    .card_top {
      padding: spacing(6);

      h1,
      h3 {
        color: var(--color-product-text);
        font-size: 22px;
        line-height: 32px;
        margin-bottom: spacing(3);
        word-break: break-word;
      }
    }

    .card_poll {
      padding-bottom: spacing(4);
      @include up(desktop) {
        padding: 0 spacing(6) spacing(4) spacing(6);
      }
    }
  }

  .comments {
    margin: 0 auto;
    width: calc(100% - spacing(8));
    @include up(tablet) {
      width: unset;
    }
  }
}

@include up(desktop) {
  .card_wrapper {
    .card {
      min-width: 710px;
      position: relative;
      z-index: 2;

      .card_top {
        h1,
        h3 {
          font-size: 22px;
          line-height: 32px;
          margin-bottom: spacing(6);
        }
      }
    }
  }
}

.inner_driver {
  border-top: 1px solid var(--color-neutral-300);
}

@include up(desktop) {
  .inner_driver {
    display: none;
  }
  .stats_actions {
    padding: spacing(5) spacing(6);
  }
}

/* Actions */
.stats_actions_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include up(desktop) {
    align-items: center;
    border-top: 1px solid var(--color-neutral-300);
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.stats,
.actions {
  display: flex;
  flex-wrap: nowrap;
  padding: spacing(5);
  width: 100%;
  @include up(desktop) {
    padding: 0;
  }
}

.stats {
  justify-content: flex-end;
}

.actions {
  justify-content: space-around;
  @include up(desktop) {
    gap: 15px;
    justify-content: flex-start;
  }
}
