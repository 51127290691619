@import 'utils';

.button:has(span) {
  align-items: center;
  background-color: var(--color-utility-interaction-200);
  border: none;
  color: var(--color-white);
  display: inline-flex;
  justify-content: center;
  padding: spacing(3) spacing(4);
  transition: all 0.2s ease-in-out;
  width: 100%;

  &:hover {
    background-color: var(--color-utility-interaction-100);
    border: none;
    box-shadow: var(--box-shadow-small);
    transform: scale(1.01);
  }
}
