@import 'utils';

.tag {
  cursor: pointer;
  border: 1px solid var(--interaction-03-default);
  border-radius: 24px;
  color: var(--color-product-text);
  display: inline-flex;
  padding: 6px spacing(3);
  transition: border 0.16s ease-in-out;

  &.active,
  &:hover,
  &:focus {
    border: 1px solid var(--color-utility-interaction-500);
  }

  &:focus {
    border-width: 2px;
  }

  &.active {
    margin: 4px;
    outline: 2px solid var(--color-utility-interaction-200);
    outline-offset: 2px;
  }
}
