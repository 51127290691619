@import 'utils';

.root {
  padding-bottom: 50px;
  padding-top: 50px;
  text-align: center;
}

.container {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  gap: spacing(5);
  width: 260px;
}

.cta {
  display: flex;
  height: 100px;
  width: 120px;

  img {
    margin: auto;
  }
}

.email {
  background: rgb(246, 185, 59);
}

.twitter {
  background: rgb(5, 169, 244);
}

.linkedin {
  background: rgb(1, 119, 183);
}

.hyperlink {
  background: var(--color-foreground-hex, #000);
}
