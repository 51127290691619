@import 'utils';

.stats_bar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: spacing(2) spacing(6);
  @include up(desktop) {
    padding: spacing(2) 0;
  }

  .stats_bar_lightbulb {
    color: var(--color-picton-blue);
  }

  .circle {
    color: var(--color-neutral-500);
    margin: 0 2%;
  }

  & > span {
    display: flex;
  }
}
