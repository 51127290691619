@import 'utils';

.comment_box{
  position: relative;
  background: var(--color-cultured-white);
  color: var(--color-product-text);
  padding: spacing(4);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: spacing(2);
  border-radius: 4px;
}

.comment_info {
  display: flex;
  flex-direction: column;
  gap: spacing(6);
  border-radius: 4px;
}

.date {
  position: absolute;
  right: spacing(4);
  top: spacing(4);
}

.comment {
  display: flex;
  gap: spacing(2);
  justify-content: space-between;
  margin-bottom: spacing(6);
}

.title {
  color: var(--color-brand-gartner-blue)
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
