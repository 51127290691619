@import 'utils';

.feed {
  .feed_screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: spacing(6);
    margin: spacing(6);

    h2,
    h6 {
      text-align: center;
    }

    a {
      background-color: var(--color-utility-interaction-700);
      border-radius: 4px;
      color: var(--color-white);
      padding: spacing(3) spacing(6);
      font-weight: 500;
    }

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .spinner {
    padding: spacing(4);
  }
}
