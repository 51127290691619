@import 'utils';

.sort_by {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;

  & > * + * {
    margin-left: 10px;
  }
}
