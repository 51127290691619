@import 'utils';

.small_card {
  padding: spacing(6);
  background: var(--neutrals-01-white);
  box-shadow: var(--box-shadow-01-normal);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

  &:hover {
    box-shadow: var(--box-shadow-01-raised);
    transform: scale(1.025, 1.025);
  }
}

.content_type {
  display: block;
  text-transform: uppercase;
}

.content_type,
.title {
  margin-bottom: spacing(3);
}

.title {
  font-weight: 500;
  color: var(--color-brand-gartner-blue);
}
