@import 'utils';

.user_notifications {
  position: relative;

  .badge {
    background: var(--color-utility-error-100);
    border-radius: 50%;
    color: var(--color-background-hex);
    height: 16px;
    line-height: 16px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 16px;
  }
}
