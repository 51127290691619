@import 'utils';

.topic_feed {
  .title {
    padding-bottom: spacing(6);
  }

  .anonymous_card > div {
    box-shadow: none;
    border: none;
  }

  .preloading {
    padding: spacing(8) 0;
  }
}
