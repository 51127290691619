@import 'utils';

.search {
  background-color: var(--color-background-hex);
  display: none;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: spacing(3);
  z-index: 1;
  border: 1px solid var(--color-neutral-500);
  border-radius: 8px;
  color: var(--color-product-text);
  position: relative;

  &.search_true {
    display: flex;
  }

  &:hover,
  &:focus-within {
    border-color: var(--color-utility-interaction-500);
  }

  @include up(tablet) {
    display: flex;
  }

  svg {
    display: none;
  }

  .tag_link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
