@import 'utils';

.dropdown,
.menu_item {
  align-items: center;
  display: flex;
  gap: spacing(2);
  height: 100%;
  color: var(--color-brand-gartner-blue);
  font-weight: 500;
}

.peer_finder:not(:last-child) {
  border-bottom: 0;
}
