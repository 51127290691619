@import 'utils';

.tag_list {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  gap: spacing(2) spacing(3);
  grid-area: tags;
  margin-block: spacing(6);
}

.show_more {
  color: var(--color-brand-gartner-blue);
  cursor: pointer;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
}
