@import 'utils';

.wrapper .tabs {
  color: var(--color-product-text);
  margin-bottom: 20px;
  border-bottom: 4px solid #e6eaee;

  .tab {
    padding: spacing(3) spacing(5);
    font-weight: 500;
    border-width: 4px;
    top: spacing(1);
    font-size: 19px;
    line-height: 27px;
    text-transform: none;
  }

  .selected {
    color: var(--color-utility-interaction-500);
    border-color: var(--color-utility-interaction-500);
  }
}
