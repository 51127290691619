@import 'utils';

.join {
  backdrop-filter: blur(6px);
  background-color: rgba($color: var(--background-rgb), $alpha: 0.7);
  display: flex;
  justify-content: center;
  position: sticky;
  text-align: center;

  &.shortVersion {
    width: 100%;
  }

  .message {
    height: fit-content;
    position: sticky;
    top: 100px;

    > :not(:last-child) {
      margin-bottom: spacing(6);
    }

    .feature_list {
      list-style: none;
      padding: 0 spacing(4);
      text-align: left;

      > li:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }

    .lock_icon {
      margin-bottom: spacing(3);
    }
  }
}

.content {
  min-height: 300px;
  padding: spacing(2);
  position: relative;

  @include down(phone) {
    min-height: 350px;
  }

  .join {
    inset: 0;
    padding: spacing(6) 0;
    position: absolute;
    z-index: 5;
  }

  .anonymous_card > div {
    border: none;
    box-shadow: none;
  }
}
