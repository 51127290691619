@import 'utils';

.user_actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: spacing(4);
  
  button:has(> svg) {
    color: var(--color-product-text);
  }
}